<template>
	<div class="page">
		<div class="toolbar" ref="toolbar">
			<div class="tit">
				{{ this.list.length > 0 ? this.list[0].device_name : "" }}
				<span class="desc">{{ this.list.length }}个插座</span>
			</div>
			<div class="type"></div>
			<div class="action-box">
				<el-button @click="refresh" size="small" icon="el-icon-refresh-right" plain>刷新
				</el-button>
				<el-button @click="close" size="small" icon="el-icon-close" plain>取消
				</el-button>
			</div>
		</div>
		<el-card class="plugs-list">
			<div class="plug-item" v-for="(item, index) in list" :key="item.plug_id" @click="qrCode(item, 1)" :id="index">
				<div class="one-box" :class="'status' + item.plug_status">
					<div @click.stop="deleteOne(item)"
						style="text-align: right;width: 100%;margin: -15px -15px 0 0;font-size: 14px;color: #999;">X</div>
					<div>
						<div class="name">{{ item.plug_name }}</div>
						<div class="num">{{ item.plug_num }}(
							<span v-if="item.online_status == 10">{{ online_status[item.online_status] }}</span>
							<span v-if="item.online_status == 20" style="color: red;font-weight: bold">{{
								online_status[item.online_status] }}</span>
							)
						</div>
					</div>
					<div class="status" @click.stop="editOne(item)">
						{{ plug_status[item.plug_status] }}
					</div>
				</div>
			</div>
			<br />


			<div class="plug-item" v-for="(item, index) in list" :key="item.plug_id" @click="qrCode(item, 2)" :id="index">
				<div class="one-box" :class="'status' + item.plug_status">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
						<path fill="#dd4040"
							d="M3.615 19q-.69 0-1.152-.462Q2 18.075 2 17.385V6.615q0-.69.463-1.152Q2.925 5 3.615 5h16.77q.69 0 1.152.463q.463.462.463 1.152v10.77q0 .69-.462 1.152q-.463.463-1.153.463zm.27-4.308h.827v-4l2.703 4h.854V9.308h-.827v4l-2.654-4h-.903zm5.923 0h3.384v-.827h-2.5v-1.407h2.5v-.827h-2.5v-1.496h2.5v-.827H9.808zm5.615 0h4q.31 0 .501-.191t.191-.501V9.308h-.827v4.5h-1.446v-3.5h-.827v3.5h-1.457v-4.5h-.827V14q0 .31.191.501t.501.191" />
					</svg>

					<div @click.stop="deleteOne(item)"
						style="text-align: right;width: 100%;margin: -15px -15px 0 0;font-size: 14px;color: #999;">X</div>
					<div>
						<div class="name">{{ item.plug_name }}</div>
						<div class="num">{{ item.plug_num }}(
							<span v-if="item.online_status == 10">{{ online_status[item.online_status] }}</span>
							<span v-if="item.online_status == 20" style="color: red;font-weight: bold">{{
								online_status[item.online_status] }}</span>
							)
						</div>
					</div>
					<div class="status" @click.stop="editOne(item)">
						{{ plug_status[item.plug_status] }}
					</div>
				</div>
			</div>



		</el-card>

		<!-- 二维码 -->
		<el-dialog title="插座二维码" :visible.sync="showQrCode" width="600px" height="650px" center class="qrcode"
			@closed="closedQrcode">
			<div class="qr-code">
				<div class="qr-code-box">
					<div class="qrcode-content" v-if="qrcode">
						<div class="qrcode-url">
							<img :src="qrcode" />
						</div>
					</div>

					<span slot="footer" class="dialog-footer">
						<el-button size="small" @click="qrcodeDownload('wxcode')">下载小程序码</el-button>
					</span>
				</div>
			</div>
		</el-dialog>



		<el-dialog title="插座二维码" :visible.sync="showQrCode2" width="600px" height="650px" center class="qrcode"
			@closed="closedQrcode">
			<div class="qr-code">
				<div class="qr-code-box" style="height: auto;">
					<div class="qrcode-content" v-if="qrcode" style="height: auto;">
						<div class="qrcode-url" style="height: auto;">
							<img :src="qrcode2" />
						</div>
					</div>

					<span slot="footer" class="dialog-footer">
						<el-button size="small" @click="qrcodeDownload('wxcode2')">下载小程序码</el-button>
					</span>
				</div>
			</div>
		</el-dialog>


		<!-- 编辑 -->
		<el-dialog title="编辑插座" :visible.sync="editDialogShow" :close-on-click-modal="false" :close-on-press-escape="false"
			width="500px">
			<el-form :model="editDialogFormData" :rules="rules" ref="editForm" label-width="160px" class="form">
				<el-form-item label="插座名称">{{
					editDialogFormOriData.plug_name
				}}</el-form-item>
				<el-form-item label="插座序号">{{
					editDialogFormOriData.plug_num
				}}</el-form-item>
				<el-form-item label="插座状态" prop="plug_status">
					<el-radio-group v-model="editDialogFormData.plug_status">
						<el-radio :label="20">正常</el-radio>
						<el-radio :label="30">异常</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveEdit('editForm')"
					:disabled="editDisabled" v-if="$hasAccess('device.plug/edit')">保 存 编 辑
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {
	mapMutations
} from "vuex";
import CodeBj from "@/assets/codeBj.png";
import { defineComponent } from 'vue';


export default {
	name: "device-device-plugs",
	data() {
		return {
			list: [],
			device_id: undefined,
			plug_status: {
				20: "未使用",
				30: "异常",
				40: "充电中",
				50: "已插枪",
				60: "占用",
				70: "故障",
				80: "离线",
			},
			online_status: {
				10: "在线",
				20: "离线"
			},
			// 二维码弹窗
			showQrCode: false,
			showQrCode2: false,
			selPlugDetail: undefined,
			qrcode: undefined, // 对应小程序码
			linkQrcode: undefined, // 对应关联到小程序码的二维码
			qrcode2: undefined, // 对应关联到小程序码的二维码
			// 规则
			rules: {
				plug_status: [{
					required: true,
					message: "请选择插座状态",
					trigger: "change"
				},],
			},
			// 编辑
			editDialogShow: false,
			editDialogFormData: {},
			editDialogFormOriData: {},
			editNeedData: ["plug_id", "plug_status"],
		};
	},
	computed: {
		editDisabled() {
			for (let item of this.editNeedData) {
				if (this.editDialogFormData[item] != this.editDialogFormOriData[item]) {
					return false;
				}
			}
			return true;
		},
	},
	mounted() {
		this.init();
		this.canvasInit();
	},
	async activated() {
		this.init();
	},
	methods: {
		...mapMutations(["setTabList"]),
		init() {
			if (this.$route.query.device_id) {
				if (this.device_id != this.$route.query.device_id) {
					this.device_id = this.$route.query.device_id;
				}
				this.getList();
			} else {
				this.$confirm("当前页面数据失效，请返回项目列表重新打开", "提示", {
					cancelButtonClass: "btn-custom-cancel",
					confirmButtonText: "确定",
					showCancelButton: false,
					showClose: false,
					closeOnClickModal: false,
					type: "warning",
				}).then(() => {
					this.closeTab();
				});
			}
		},
		async getList() {
			const params = {
				token: this.$store.state.user.token,
				page: 1,
				size: 100,
			};
			this.isLoading = true;
			const searchData = {
				device_id: this.device_id,
			};
			const res = await this.$api.Device.GetPlugList(params, searchData);
			this.list = res.data;
			this.isLoading = false;
		},
		// 刷新
		refresh() {
			this.getList();
		},
		// 关闭提示
		close() {
			let tabs = this.$store.state.app.tabList;
			this.setTabList(tabs.filter((tab) => tab.name !== "device-device-plug"));
			this.$router.push({
				name: "device-device-list",
			});
		},
		// canvas初始化
		canvasInit() {
			const canvas = document.createElement("canvas");
			canvas.id = "canvas1";
			console.log(canvas);
			canvas.width = 500;
			canvas.height = 510; // 560
			const ctx = canvas.getContext("2d");
			ctx.textAlign = "left";
			ctx.textBaseline = "top";
			ctx.font = "34px Microsoft YaHei";
			this.canvas = canvas;
			this.ctx = ctx;

			const canvas2 = document.createElement("canvas");
			canvas2.id = "canvas2";
			console.log(canvas2);
			canvas2.width = 500;
			canvas2.height = 510; // 560
			const ctx2 = canvas2.getContext("2d");
			ctx2.textAlign = "left";
			ctx2.textBaseline = "top";
			ctx2.font = "34px Microsoft YaHei";
			this.canvas2 = canvas2;
			this.ctx2 = ctx2;


			const canvas3 = document.createElement("canvas");
			canvas3.id = "canvas2";
			canvas3.width = 500;
			canvas3.height = 854; // 560
			const ctx3 = canvas3.getContext("2d");
			ctx3.textAlign = "left";
			ctx3.textBaseline = "top";
			ctx3.font = "34px Microsoft YaHei";
			this.canvas3 = canvas3;
			this.ctx3 = ctx3;

		},
		// 二维码
		qrCode(row, type) {
			this.selPlugDetail = row;
			if (type == 1) {
				this.showQrCode = true;
			}
			if (type == 2) {
				this.showQrCode2 = true;
			}

			if (!row.app_qrcode || !row.link_qrcode) {
				const params = {
					token: this.$store.state.user.token,
					device_id: row.device_id,
					plug_id: row.plug_id,
				};
				let that = this;
				this.$api.Device.QrCodeDevice(params).then((res) => {
					if (!res[0] || !res[1]) {
						if (type == 1) {
							this.showQrCode = false;
						}
						if (type == 2) {
							this.showQrCode2 = false;
						}
						this.$notify({
							title: "请重试",
							message: "二维码生成失败",
							type: "error",
						});
						this.$refs.xTable.refreshTable();
					} else {
						row.qrcode = res[0];
						row.link_qrcode = res[1];
						that.drawQRCode(row);
					}
				});
			} else {

				this.drawQRCode(row);
			}
		},

		drawQRCode(row) {
			let that = this;
			this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
			// 画图
			this.ctx.fillStyle = "#fff";
			this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
			this.ctx.fillStyle = "#008e4d";
			this.ctx.textAlign = "center";
			let showName = row.device_no + "-" + row.plug_num;
			if (row.plug_name.indexOf('P') != -1) {
				showName = row.plug_name;
			}

			if (showName.length > 20) {
				this.ctx.font = "25px Microsoft YaHei";
			}
			if (showName.length > 28) {
				this.ctx.font = "21px Microsoft YaHei";
			}

			this.ctx.fillText(showName, this.canvas.width / 2, 20);
			const image = new Image();
			image.setAttribute("crossOrigin", "anonymous");
			image.src = row.qrcode;
			image.onload = () => {
				that.ctx.drawImage(image, 35, 75, 430, 430);
				that.qrcode = that.canvas.toDataURL();
			};

			this.ctx2.clearRect(0, 0, this.canvas2.width, this.canvas2.height);
			// 画图
			this.ctx2.fillStyle = "#fff";
			this.ctx2.fillRect(0, 0, this.canvas2.width, this.canvas2.height);
			this.ctx2.fillStyle = "#008e4d";
			this.ctx2.textAlign = "left";
			this.ctx2.font = "34px Microsoft YaHei";
			this.ctx2.fillText("设备编号：", 35, 20);
			this.ctx2.textAlign = "right";
			this.ctx2.fillText(showName, 465, 20);

			const image2 = new Image();
			image2.setAttribute("crossOrigin", "anonymous");
			image2.src = row.link_qrcode;
			image2.onload = () => {
				that.ctx2.drawImage(image2, 15, 50, 470, 535);
				that.linkQrcode = that.canvas2.toDataURL();
			};

			const backgroundImage = new Image();
			backgroundImage.src = CodeBj;
			backgroundImage.onload = () => {
				// 绘制背景图
				that.ctx3.drawImage(backgroundImage, 0, 0, that.canvas3.width, that.canvas3.height);
				// 绘制二维码
				const qrCodeImage = new Image();
				qrCodeImage.src = row.qrcode;
				qrCodeImage.setAttribute("crossOrigin", "Anonymous");
				qrCodeImage.onload = function () {
					that.ctx3.drawImage(qrCodeImage, 70, 210, 360, 360);
					// 添加文字
					that.ctx3.font = "30px pingfang";
					that.ctx3.fillStyle = "white";
					that.ctx3.textAlign = "center";
					that.ctx3.fontWeight = "bold";
					that.ctx3.textBaseline = "middle"
					that.ctx3.fillText(
						row.device_no + "-" + row.plug_num,
						that.canvas3.width / 2, 610
					);
					that.qrcode2 = that.canvas3.toDataURL("image/png");
				};
			};
		},

		// 下载二维码
		qrcodeDownload(type) {
			if (type == "wxcode") {
				const aDom = document.createElement("a");
				const event = new MouseEvent("click");
				aDom.download =
					"插座小程序码_" +
					this.selPlugDetail.device_no +
					"_" +
					this.selPlugDetail.plug_num;
				aDom.href = this.qrcode;
				aDom.target = "_blank";
				aDom.dispatchEvent(event);
			} else if (type == "linkcode") {
				const aDom = document.createElement("a");
				const event = new MouseEvent("click");
				aDom.download =
					"插座二维码_" +
					this.selPlugDetail.device_no +
					"_" +
					this.selPlugDetail.plug_num;
				aDom.href = this.linkQrcode;
				aDom.target = "_blank";
				aDom.dispatchEvent(event);
			}
			else if (type == "wxcode2") {
				const aDom = document.createElement("a");
				const event = new MouseEvent("click");
				aDom.download =
					"插座小程序码_" +
					this.selPlugDetail.device_no +
					"_" +
					this.selPlugDetail.plug_num;
				aDom.href = this.qrcode2;
				aDom.target = "_blank";
				aDom.dispatchEvent(event);
			}
		},
		// 关闭二维码
		closedQrcode() {
			this.qrcode = undefined;
			this.linkQrcode = undefined;
		},
		// 重置表单
		resetFormData() {
			this.editDialogFormData = {};
			this.editDialogFormOriData = {};
			if (this.$refs.editForm) {
				this.$refs.editForm.resetFields();
			}
		},
		// 编辑
		async editOne(item) {
			this.resetFormData();
			if (item) {
				const {
					plug_name,
					plug_num,
					plug_status,
					plug_id
				} = item;
				const device_id = this.device_id;
				this.editDialogFormData = {
					device_id,
					plug_name,
					plug_num,
					plug_status,
					plug_id,
				};
				this.editDialogFormOriData = {
					plug_name,
					plug_num,
					plug_status,
					plug_id,
				};
			}
			this.editDialogShow = true;
		},
		// 关闭弹窗
		closeDialog() {
			this.editDialogShow = false;
		},
		// 编辑保存
		saveEdit() {
			this.$refs.editForm.validate(async (valid) => {
				if (valid) {
					const params = {
						token: this.$store.state.user.token,
						form: JSON.stringify(this.editDialogFormData),
					};
					await this.$api.Device.EditPlug(params);
					this.$notify({
						title: "成功",
						message: "编辑成功",
						type: "success",
					});
					this.closeDialog();
					this.getList();
				}
			});
		},
		// 删除插口
		deleteOne(row) {
			this.$confirm('确定要删除该插口吗', '提示', {
				cancelButtonClass: 'btn-custom-cancel',
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(async () => {
				const params = {
					token: this.$store.state.user.token,
					plug_id: row.plug_id,
				};
				await this.$api.Device.DeletePlug(params);
				this.$notify({
					title: "成功",
					message: "删除成功",
					type: "success",
				});
				this.getList();
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.toolbar .desc {
	color: #666;
	font-size: 14px;
	margin-left: 30px;

	&::before {
		content: "【";
	}

	&::after {
		content: "】";
	}
}

.plugs-list {
	flex-grow: 1;
	margin: 10px 0;
	display: flex;
	flex-wrap: wrap;
	overflow-y: scroll;
	justify-content: flex-start;
	align-content: flex-start;
}

.plug-item {
	width: 120px;
	height: 120px;
	float: left;
	margin-right: 20px;
	margin-bottom: 20px;
	border-radius: 5px;
	cursor: pointer;
}

.one-box {
	width: 100%;
	height: 100%;
	border: 1px solid #e8eaec;
	background: #f1f1f1;
	box-sizing: border-box;
	padding: 15px;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	position: relative;


	svg {
		position: absolute;
		left: 5px;
		top: 0px;
		width: 25px;
	}

	.num {
		font-size: 12px;
		color: #999;

		&::before {
			content: "编号：";
		}
	}

	.status {
		padding: 0 15px;
		border-radius: 24px;
		font-size: 14px;
		line-height: 24px;
		background: #999;
		color: #fff;
	}

	&.status30 {
		.status {
			background: #f56c6c;
		}
	}

	&.status40 {
		.status {
			background: #67c23a;
		}
	}
}

.qrcode {
	.device-name {
		text-align: center;
	}

	.qrcode-url {
		width: 250px;
		height: 250px;
		margin: 0 auto;

		img {
			width: 100%;
		}
	}
}

.qr-code {
	display: flex;

	.qr-code-box {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 530px;
		height: 300px;

		.qrcode-content {
			height: 300px;
		}
	}
}

.seticon {
	position: absolute;
	top: 20px;
	right: 20px;
}
</style>
